import { toDisplayString as _toDisplayString, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, vModelDynamic as _vModelDynamic, normalizeStyle as _normalizeStyle, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, KeepAlive as _KeepAlive, Transition as _Transition, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-343f1fa4"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["for"]
const _hoisted_2 = ["id", "type", "placeholder", "autocomplete", "disabled", "readonly", "data-qa"]
const _hoisted_3 = {
  key: 0,
  class: "input__text"
}
const _hoisted_4 = {
  key: 1,
  class: "input__error"
}
const _hoisted_5 = { class: "input__error-text" }
const _hoisted_6 = ["data-qa"]
const _hoisted_7 = {
  key: 4,
  class: "input__copy"
}
const _hoisted_8 = {
  key: 0,
  class: "input__tooltip"
}
const _hoisted_9 = ["data-qa"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_info = _resolveComponent("info")
  const _component_error = _resolveComponent("error")
  const _component_hide = _resolveComponent("hide")
  const _component_vision = _resolveComponent("vision")
  const _component_closeIcon = _resolveComponent("closeIcon")
  const _component_copy = _resolveComponent("copy")

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["input", {
      empty: $props.type === 'empty',
      noIcon: !$props.icon,
      error: $props.error,
      'input--type--currency': $setup.isTypeCurrency,
    }])
  }, [
    _createElementVNode("label", {
      for: $props.id,
      class: _normalizeClass({ warn: $props.warningLabel })
    }, [
      _createTextVNode(_toDisplayString($props.label) + " ", 1),
      ($props.labelInfo)
        ? (_openBlock(), _createBlock(_component_info, {
            key: 0,
            class: "input__label-info"
          }))
        : _createCommentVNode("", true)
    ], 10, _hoisted_1),
    _withDirectives(_createElementVNode("input", {
      id: $props.id,
      ref: "inputRef",
      "onUpdate:modelValue": _cache[0] || (_cache[0] = $event => (($setup.valueRef) = $event)),
      type: $setup.inputType,
      placeholder: $setup.dynamicPlaceholder,
      autocomplete: $props.autocomplete ? 'on' : 'off',
      disabled: $props.disabled,
      spellcheck: "false",
      style: _normalizeStyle({ background: $props.background }),
      readonly: $props.hardAutocompleteOff || $setup.resetAutocomplete,
      onfocus: "this.removeAttribute('readonly')",
      "data-qa": $props.dataQa,
      onInput: _cache[1] || (_cache[1] = (...args) => ($setup.inputHandler && $setup.inputHandler(...args))),
      onFocus: _cache[2] || (_cache[2] = (...args) => ($setup.onFocusHandler && $setup.onFocusHandler(...args))),
      onBlur: _cache[3] || (_cache[3] = (...args) => ($setup.onBlurHandler && $setup.onBlurHandler(...args)))
    }, null, 44, _hoisted_2), [
      [_vModelDynamic, $setup.valueRef]
    ]),
    ($props.type === 'number' && $props.text)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString($props.text), 1))
      : _createCommentVNode("", true),
    ($props.error && $props.showErrorText)
      ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_error, { class: "input__error-icon" }),
          _createElementVNode("span", _hoisted_5, _toDisplayString($props.error), 1)
        ]))
      : _createCommentVNode("", true),
    ($props.icon)
      ? (_openBlock(), _createBlock(_KeepAlive, { key: 2 }, [
          (_openBlock(), _createBlock(_resolveDynamicComponent($setup.currentIcon), {
            class: _normalizeClass(["input__icon", { 'input__icon--disabled': $props.disabled }]),
            "data-icon": $props.dataQa && `${$props.dataQa}__icon`,
            onClick: _cache[4] || (_cache[4] = $event => (_ctx.$emit('iconClick')))
          }, null, 8, ["class", "data-icon"]))
        ], 1024))
      : _createCommentVNode("", true),
    ($props.type === 'password')
      ? (_openBlock(), _createElementBlock("div", {
          key: 3,
          "data-qa": $props.dataQa && `${$props.dataQa}__visible-button`,
          onClick: _cache[5] || (_cache[5] = $event => ($setup.hide = !$setup.hide))
        }, [
          _createVNode(_Transition, { name: "fade" }, {
            default: _withCtx(() => [
              ($setup.hide)
                ? (_openBlock(), _createBlock(_component_hide, {
                    key: 0,
                    class: "input__visibility-icon"
                  }))
                : (_openBlock(), _createBlock(_component_vision, {
                    key: 1,
                    class: "input__visibility-icon"
                  }))
            ]),
            _: 1
          })
        ], 8, _hoisted_6))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        ($props.clearable && $setup.valueRef)
          ? (_openBlock(), _createElementBlock("div", {
              key: 0,
              class: "input__clear-icon",
              onClick: _cache[6] || (_cache[6] = (...args) => ($setup.clearInput && $setup.clearInput(...args)))
            }, [
              _createVNode(_component_closeIcon)
            ]))
          : _createCommentVNode("", true)
      ]),
      _: 1
    }),
    ($props.withCopy && $setup.valueRef)
      ? (_openBlock(), _createElementBlock("div", _hoisted_7, [
          _createVNode(_component_copy, {
            "data-qa": $props.dataQa && `${$props.dataQa}__copy-button`,
            onClick: $setup.copyValue
          }, null, 8, ["data-qa", "onClick"]),
          _createVNode(_Transition, { name: "fade1" }, {
            default: _withCtx(() => [
              ($setup.isCopied)
                ? (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('copiedToClipboard')), 1))
                : _createCommentVNode("", true)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    ($props.showSetMax)
      ? (_openBlock(), _createElementBlock("div", {
          key: 5,
          class: "input__max",
          "data-qa": $props.dataQa && `${$props.dataQa}__max-button`,
          onClick: _cache[7] || (_cache[7] = (...args) => ($setup.setMax && $setup.setMax(...args)))
        }, _toDisplayString(_ctx.$t('max')), 9, _hoisted_9))
      : _createCommentVNode("", true),
    ($props.currency)
      ? (_openBlock(), _createElementBlock("span", {
          key: 6,
          class: "input__currency",
          style: _normalizeStyle({ left: `${$setup.currencyOffset}px` })
        }, _toDisplayString($props.currency), 5))
      : _createCommentVNode("", true),
    _createElementVNode("span", {
      ref: "currencyTextRef",
      class: "input__hidden-value"
    }, _toDisplayString($setup.valueRef || $setup.dynamicPlaceholder), 513)
  ], 2))
}