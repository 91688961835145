import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, normalizeStyle as _normalizeStyle, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-66c57806"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["id", "disabled", "data-qa"]

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_loadingIcon = _resolveComponent("loadingIcon")

  return (_openBlock(), _createElementBlock("button", {
    id: $props.id,
    style: _normalizeStyle($options.cssVars),
    disabled: $props.disabled,
    class: _normalizeClass(["primary-button", { 'no-decoration': $props.noDecoration, loading: $props.loading }]),
    "data-qa": $props.dataQa,
    onClick: _cache[0] || (_cache[0] = $event => ($props.loading ? () => {} : _ctx.$emit('click', $event)))
  }, [
    ($props.loading)
      ? (_openBlock(), _createBlock(_component_loadingIcon, {
          key: 0,
          class: "rotating"
        }))
      : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
  ], 14, _hoisted_1))
}