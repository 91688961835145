import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx } from "vue"
import _imports_0 from '@/assets/gif/loader.gif'


const _hoisted_1 = {
  key: 0,
  src: _imports_0,
  alt: ""
}

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_AddressAlreadyAdded = _resolveComponent("AddressAlreadyAdded")
  const _component_CatPage = _resolveComponent("CatPage")
  const _directive_click_away = _resolveDirective("click-away")

  return (_openBlock(), _createBlock(_TransitionGroup, { name: "fade" }, {
    default: _withCtx(() => [
      ($setup.showLoader)
        ? (_openBlock(), _createElementBlock("img", _hoisted_1))
        : _createCommentVNode("", true),
      ($setup.showAlreadyAddedModal)
        ? _withDirectives((_openBlock(), _createBlock(_component_AddressAlreadyAdded, {
            key: 1,
            onClose: $setup.modalCloseHandler,
            onButtonClick: $setup.modalCloseHandler
          }, null, 8, ["onClose", "onButtonClick"])), [
            [_directive_click_away, $setup.modalCloseHandler]
          ])
        : _createCommentVNode("", true),
      ($setup.showModal && !$setup.showAlreadyAddedModal)
        ? _withDirectives((_openBlock(), _createBlock(_component_CatPage, {
            key: 2,
            data: $setup.newWalletList,
            "wallet-type-placeholder": $setup.walletTypePlaceholder,
            onClose: $setup.modalCloseHandler,
            onButtonClick: $setup.modalCloseHandler,
            "input-type-icon": $setup.inputTypeIcon,
            "data-qa": $setup.dataQa
          }, null, 8, ["data", "wallet-type-placeholder", "onClose", "onButtonClick", "input-type-icon", "data-qa"])), [
            [_directive_click_away, $setup.modalCloseHandler]
          ])
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}