import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx, _cache, $props, $setup, $data, $options) {
  const _component_router_view = _resolveComponent("router-view")
  const _component_AppLayout = _resolveComponent("AppLayout")
  const _component_Toasts = _resolveComponent("Toasts")
  const _component_MobileAppModal = _resolveComponent("MobileAppModal")
  const _component_Modal = _resolveComponent("Modal")
  const _component_NewWalletsModal = _resolveComponent("NewWalletsModal")

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_AppLayout, null, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_Teleport, { to: "body" }, [
      _createVNode(_component_Toasts)
    ])),
    ($setup.showModal)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 0,
          to: "body"
        }, [
          _createVNode(_component_Modal, null, {
            default: _withCtx(() => [
              _createVNode(_component_MobileAppModal, { onClose: $setup.closeMobileModal }, null, 8, ["onClose"])
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true),
    ($setup.newWalletsModalShow)
      ? (_openBlock(), _createBlock(_Teleport, {
          key: 1,
          to: "body"
        }, [
          _createVNode(_component_Modal, null, {
            default: _withCtx(() => [
              _createVNode(_component_NewWalletsModal)
            ]),
            _: 1
          })
        ]))
      : _createCommentVNode("", true)
  ], 64))
}